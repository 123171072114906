import React, { useEffect, useState, useContext } from 'react';
import { } from 'react-router-dom';
import { } from 'antd';
import { } from "../../server/https";

const My_report = () => {
  const [data, dataSet] = useState([])

  useEffect(() => {

  }, [])

  return (
    <div>
      <div>
        <div>0/30</div>
        <div>0% </div>
      </div>
      <div>
        <div>

        </div>
        <div>

        </div>
      </div>
    </div>
  )
}

export default My_report;