import React, { useEffect, useState } from 'react';
import { Input, Button, message } from "antd";
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { login } from "../server/https";
import { useHistory } from 'react-router-dom'
const params_modal = {
  username: '',
  password: ''
}
const LoginPage = () => {
  let history = useHistory()
  const [params, paramsSet] = useState(params_modal)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/')
    }
  }, [history])

  const onChange = (e, id) => {
    let data = params
    data[id] = e.target.value
    paramsSet(data)
  }

  const loginFun = e => {
    let data = params
    login(data).then(res => {
      if (res.code === 0) {
        localStorage.setItem('company_id', res.data.model.company_id)
        localStorage.setItem('username', params.username)
        localStorage.setItem('permission', res.data.model.permission)
        localStorage.setItem('token', res.data.model.token)
        localStorage.setItem('id', res.data.model.id)
        history.push('/')
      } else {
        message.error(res.message)
      }
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#00A0ff' }}>
      <div style={{ width: 350, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', padding: '20px', border: '1px #fff solid', borderRadius: '20px 20px 20px 20px' }}>
        <div style={{ fontWeight: '800', fontSize: '24px', marginBottom: '20px' }}>OA中台</div>
        <Input placeholder="请输入账号" prefix={<UserOutlined />} onChange={e => onChange(e, 'username')} style={{ marginBottom: '10px' }} />
        <Input.Password placeholder="请输入密码" prefix={<UnlockOutlined />} onChange={e => onChange(e, 'password')} style={{ marginBottom: '10px' }} />
        <Button type="primary" onClick={e => loginFun()} style={{ width: '80%' }}>登录</Button>
      </div>
    </div>
  )
}

export default LoginPage;