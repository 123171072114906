import React, { useEffect, useState, useContext } from 'react';
import { Tree, Form, message, Select, Input, Button, Modal } from 'antd';
import "../../css/main_list.css";
import { get_employee_list, get_department_list, add_department, edit_department, del_department } from "../../server/https";
import { changeTreeDate } from "../../common/changeTreeDate";
import myContext from '../../common/createContext'

const { Option, OptGroup } = Select;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddModal = (props) => {
  const value = useContext(myContext);
  const [form] = Form.useForm();

  const handleOk = e => {
    form.validateFields().then((values) => {
      let data = values
      data.parent_id = value.department_id
      data.name = data.title
      data.view_department_ids = data.view_department_ids ? data.view_department_ids.join(',') : []
      add_department(data).then(res => {
        if (res.code === 0) {
          message.success('修改成功！')
          props.get_department_listFun();
        } else {
          message.warn(res.message)
        }
      })
    }).catch((err) => {
      message.warn('提交失败，请检查表格！')
    });
  }

  return (
    <Modal
      title="添加子部门"
      visible={value.visible}
      onOk={handleOk}
      onCancel={() => { form.resetFields(); props.visibleChange(); }}
      width={800}
    >
      <Form
        form={form}
        {...layout}
        name="添加员工"
      >
        <Form.Item
          label="所属公司"
          name="company_id"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            {
              value.department_list.map(item =>
                <Option key={item.key} value={item.key}>{item.title}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="部门名称"
          name="title"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Input placeholder="请输入" allowClear />
        </Form.Item>

        <Form.Item
          label="是否是校区"
          name="is_campus"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'-1'}>否</Option>
            <Option value={'1'}>是</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="部门主管"
          name="leader_id"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'0'}>无</Option>
            {
              value.employee_list.map(item =>
                <Option key={item.id} value={item.id}>{item.name}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="查看权限"
          name="view_permissions"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'1'}>不能查看（仅能看自己）</Option>
            <Option value={'2'}>可以看本部门</Option>
            <Option value={'3'}>可以看指定部门</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="自定义部门"
          name="view_department_ids"
        >
          <Select mode="tags" placeholder="请选择" allowClear>
            {
              value.department_list.map(item => (
                item.children ?
                  <OptGroup key={item.key} label={item.title}>
                    {
                      item.children.map(item => (
                        <Option key={item.key} value={item.key}>{item.title}</Option>
                      ))
                    }
                  </OptGroup>
                  :
                  ''
              ))
            }
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
};

const EditorModal = (props) => {
  const [visible, visibleSet] = useState(false)
  const value = useContext(myContext);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(value.info)
  }, [value])// eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = values => {
    let data = values
    data.department_id = value.info.key
    data.name = data.title
    data.view_department_ids = data.view_department_ids.join(',')
    edit_department(data).then(res => {
      if (res.code === 0) {
        message.success('修改成功！')
        props.get_department_listFun();
      } else {
        message.warn(res.message)
      }
    })
  }

  const visibleChange = e => {
    let t = !visible
    visibleSet(t)
  }

  const del_departmentFun = e => {
    del_department({ department_id: value.info.key }).then(res => {
      if (res.code === 0) {
        message.success("删除成功！")
        props.get_department_listFun()
      }
    })
  }

  return (
    <div>
      <Form
        form={form}
        {...layout}
        onFinish={handleOk}
        onFinishFailed={e=>console.log(e)}
        name="添加员工"
      >

        <Form.Item
          label="上级部门"
          name="parent_id"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'0'}>无</Option>
            {
              value.department_list.map(item =>
                <Option key={item.key} value={item.key}>{item.title}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="所属公司"
          name="company_id"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            {
              value.department_list.map(item =>
                <Option key={item.key} value={item.company_id}>{item.title}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="部门名称"
          name="title"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Input placeholder="请输入" allowClear />
        </Form.Item>

        <Form.Item
          label="是否是校区"
          name="is_campus"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'-1'}>否</Option>
            <Option value={'1'}>是</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="部门主管"
          name="leader_id"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'0'}>无</Option>
            {
              value.employee_list.map(item =>
                <Option key={item.id} value={item.id}>{item.name}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="查看权限"
          name="view_permissions"
          rules={[
            {
              required: true,
              message: '请输入',
            },
          ]}
        >
          <Select placeholder="请选择" allowClear>
            <Option value={'1'}>不能查看（仅能看自己）</Option>
            <Option value={'2'}>可以看本部门</Option>
            <Option value={'3'}>可以看指定部门</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="自定义部门"
          name="view_department_ids"
        >
          <Select mode="tags" placeholder="请选择" allowClear>
            {
              value.department_list.map(item => (
                item.children ?
                  <OptGroup key={item.key} label={item.title}>
                    {
                      item.children.map(item => (
                        <Option key={item.key} value={item.key}>{item.title}</Option>
                      ))
                    }
                  </OptGroup>
                  :
                  ''
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">保存</Button>
          {
            value.info.parent_id === '0' ?
              <Button style={{ marginLeft: '10px' }} onClick={visibleChange}>新建子部门</Button>
              :
              <Button style={{ marginLeft: '10px' }} onClick={del_departmentFun}>删除</Button>
          }
        </Form.Item>

      </Form>
      <myContext.Provider
        value={{
          visible: visible,
          department_list: value.department_list,
          employee_list: value.employee_list,
          department_id: value.info.key
        }} >
        <AddModal visibleChange={visibleChange} get_department_listFun={props.get_department_listFun} />
      </myContext.Provider>
    </div>
  );
};

const Department_management = (props) => {
  const [department_list, department_listSet] = useState([])
  const [employee_list, employee_listSet] = useState([])
  const [info, infoSet] = useState({})

  useEffect(() => {
    get_department_listFun()
    get_employee_list().then(res => {
      if (res.code === 0) {
        employee_listSet(res.data.list)
      }
    })
  }, [])

  const get_department_listFun = () => {
    get_department_list().then(res => {
      if (res.code === 0) {
        let newlist = changeTreeDate(res.data.list, 'title', 'name')
        newlist = changeTreeDate(newlist, 'key', 'id')
        department_listSet(newlist)
        infoSet({})
      }
    })
  }

  const onDragEnter = (info, data) => {
    let node = data.node
    node.view_department_ids = node.view_department_ids ? node.view_department_ids.split(',') : []
    infoSet(node)
  }

  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...department_list];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    department_listSet(data)
  };

  return (
    <div style={{ height: '100%' }}>
      <div className='flex_box'>
        <div className='left_box'>
          <Tree
            className="draggable-tree"
            blockNode
            showLine={true}
            onSelect={onDragEnter}
            onDrop={e => onDrop()}
            treeData={department_list}
          />
        </div>
        <div className='right_box'>
          {
            JSON.stringify(info) !== '{}' ?
              <div>
                <myContext.Provider value={{ department_list: department_list, info: info, employee_list: employee_list }} >
                  <EditorModal get_department_listFun={get_department_listFun} />
                </myContext.Provider>
              </div>
              :
              ''
          }
        </div>
      </div>
    </div>
  )
}

export default Department_management;