import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Divider, Table, Button, Modal, Form, message, Select, Popconfirm, Descriptions, Input } from 'antd';
import { get_employee_unionid_list, get_union_user_list, set_employee_unionid, del_employee_unionid, get_employee_detail, my_user_list, get_my_user, del_my_user } from "../../server/https";
import { changeTreeDate } from "../../common/changeTreeDate";
import myContext from '../../common/createContext'

const { TabPane } = Tabs;
const { Option } = Select
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 12,
  },
}

let timeout;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  function fake() {
    get_union_user_list({ nickname: value }).then(res => {
      if (res.code == 0) {
        callback(res.data.list)
      }
    })
  }

  timeout = setTimeout(fake, 500);
}

const Employee_unionid_list = () => {
  let history = useHistory()
  const [total, totalSet] = useState(0)
  const [dataSource, dataSourceSet] = useState([])
  const [params, paramsSet] = useState({})
  const [user_list, user_listSet] = useState([])
  const [visible, visibleSet] = useState(false)
  const [select_value, select_valueSet] = useState()
  const value = useContext(myContext);
  const [form] = Form.useForm();

  useEffect(() => {
    get_employee_unionid_listFun()
  }, [])

  const get_employee_unionid_listFun = () => {
    let data = params
    data.oa_employee_id = history.location.pathname.split('/')[3]
    console.log(data)
    get_employee_unionid_list(data).then(res => {
      if (res.code == 0) {
        let list = changeTreeDate(res.data.list, 'key', 'id')
        dataSourceSet(list)
        totalSet(res.data.count)
      }
    })
  }

  const tableChange = (pagination) => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data.page_size = pagination.pageSize
    data.page = pagination.current
    paramsSet(data)
  }

  const confirm = (id, unionid) => {
    let data = { oa_employee_id: id, unionid: unionid }
    del_employee_unionid(data).then(res => {
      if (res.code == 0) {
        get_employee_unionid_listFun()
        message.success('删除成功！')
      } else {
        message.error('删除失败！')
      }
    })
  }

  const columns = [
    {
      title: '微信头像',
      dataIndex: 'headimgurl',
      key: 'headimgurl',
      render: (text) => (
        <img src={text} alt="" style={{ width: 40, height: 40 }} />
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '关注公众号',
      dataIndex: 'mp_str',
      key: 'mp_str',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <div>
          <Popconfirm
            title="是否确认删除？"
            onConfirm={() => confirm(record.oa_employee_id, record.unionid)}
            okText="是"
            cancelText="否"
          >
            <Button style={{ marginLeft: '10px' }}>删除</Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  const handleOk = () => {
    form.validateFields().then((values) => {
      values.oa_employee_id = value.id
      set_employee_unionid(values).then(res => {
        console.log(res)
        if (res.code == 0) {
          message.success('提交成功！')
          get_employee_unionid_listFun()
          visibleSet(false)
        } else {
          message.error(res.message)
        }
      })
    }).catch((e) => {
      console.log(e)
      message.warn('提交失败，请检查表格！')
    });
  }

  const handleSearch = value => {
    if (value) {
      fetch(value, data => user_listSet(data))
    } else {
      user_listSet([])
    }
  }

  const handleChange = value => {
    select_valueSet(value);
  }

  return (
    <div>
      <div>
        <Button onClick={() => { visibleSet(true) }}>添加</Button>
        <Modal
          title='绑定员工微信'
          visible={visible}
          onOk={handleOk}
          onCancel={() => { visibleSet(false); form.resetFields(); }}
          width={800}
        >
          <Form
            form={form}
            {...layout}
            name="添加员工"
          >
            <Form.Item
              label="微信昵称"
              name="unionid"
              rules={[
                {
                  required: true,
                  message: '请输入',
                },
              ]}
            >
              <Select
                showSearch
                placeholder={'请选择'}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
              >
                {
                  user_list.map(item => (
                    <Option key={item.unionid} value={item.unionid}><img style={{ width: '40px', height: '40px' }} src={item.headimgurl} />{item.name ? item.nickname + '-' + item.name : item.nickname}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: params.page,
            total: total,
            pageSize: params.page_size
          }}
          onChange={e => tableChange(e)}
        />
      </div>
    </div>
  )
}

const My_user_list = () => {
  let history = useHistory()
  const [total, totalSet] = useState(0)
  const [dataSource, dataSourceSet] = useState([])
  const [params, paramsSet] = useState({
    oa_employee_id: '',
    nickname: '',
    name: '',
    mobile: '',
    is_student: '',
    page: 1,
    page_size: 10
  })
  const [user_list, user_listSet] = useState([])
  const [visible, visibleSet] = useState(false)
  const [select_value, select_valueSet] = useState()
  const value = useContext(myContext);
  const [form] = Form.useForm();

  useEffect(() => {
    
  }, [])

  useEffect(() => {
    if (value.id) {
      my_user_listFun()
    }
  }, [value.id, params.page])

  const my_user_listFun = () => {
    let data = params
    data.oa_employee_id = value.id
    my_user_list(data).then(res => {
      if (res.code == 0) {
        let list = changeTreeDate(res.data.list, 'key', 'id')
        dataSourceSet(list)
        totalSet(res.data.count)
      }
    })
  }

  const tableChange = (pagination) => {
    let data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data.page_size = pagination.pageSize
    data.page = pagination.current
    paramsSet(data)
  }

  const confirm = (id, unionid) => {
    let data = { oa_employee_id: id, unionid: unionid }
    del_my_user(data).then(res => {
      if (res.code == 0) {
        my_user_listFun()
        message.success('删除成功！')
      } else {
        message.error('删除失败！')
      }
    })
  }

  const columns = [
    {
      title: '微信头像',
      dataIndex: 'headimgurl',
      key: 'headimgurl',
      render: (text) => (
        <img src={text} alt="" style={{ width: 40, height: 40 }} />
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '关注公众号',
      dataIndex: 'mp_str',
      key: 'mp_str',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <div>
          <Popconfirm
            title="是否确认删除？"
            onConfirm={() => confirm(record.oa_employee_id, record.unionid)}
            okText="是"
            cancelText="否"
          >
            <Button style={{ marginLeft: '10px' }}>删除</Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  const handleOk = () => {
    form.validateFields().then((values) => {
      values.oa_employee_id = value.id
      get_my_user(values).then(res => {
        if (res.code == 0) {
          message.success('提交成功！')
          my_user_listFun()
          visibleSet(false)
        } else {
          message.error(res.message)
        }
      })
    }).catch((e) => {
      console.log(e)
      message.warn('提交失败，请检查表格！')
    });
  }

  const inputChange = (e, id) => {
    let value = typeof (e) === 'undefined' ? '' : typeof (e) === 'object' ? e.target.value : e,
      data1 = JSON.stringify(params),
      data = JSON.parse(data1)
    data[id] = value
    paramsSet(data)
  }

  const handleSearch = value => {
    if (value) {
      fetch(value, data => user_listSet(data))
    } else {
      user_listSet([])
    }
  }

  const handleChange = value => {
    select_valueSet(value);
  }

  return (
    <div>
      <div>
        <div className='search_box'>
          昵称：<Input placeholder="请输入" onChange={e => inputChange(e, 'nickname')} allowClear />
          姓名：<Input placeholder="请输入" onChange={e => inputChange(e, 'name')} allowClear />
          是否老学员：<Select placeholder="请选择" onChange={e => inputChange(e, 'is_student')} allowClear>
            <Option value="-1">否</Option>
            <Option value="1">是</Option>
          </Select>
          手机号：<Input placeholder="请输入" onChange={e => inputChange(e, 'mobile')} allowClear />
          <Button onClick={e => {
            if (params.page === 1)
              my_user_listFun()
            else {
              let data1 = JSON.stringify(params),
                data = JSON.parse(data1)
              data.page = 1
              paramsSet(data)
            }
          }} style={{ marginRight: '10px' }}>搜索</Button>
          <Button onClick={() => { visibleSet(true) }} style={{ marginRight: '10px' }}>领取粉丝</Button>
          共：{total}条
        </div>
        <Modal
          title='领取粉丝'
          visible={visible}
          onOk={handleOk}
          onCancel={() => { visibleSet(false); form.resetFields(); }}
          width={800}
        >
          <Form
            form={form}
            {...layout}
            name="领取粉丝"
          >
            <Form.Item
              label="微信昵称"
              name="unionid"
              rules={[
                {
                  required: true,
                  message: '请输入',
                },
              ]}
            >
              <Select
                showSearch
                placeholder={'请选择'}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
              >
                {
                  user_list.map(item => (
                    <Option key={item.unionid} value={item.unionid}><img style={{ width: '40px', height: '40px' }} src={item.headimgurl} />{item.name ? item.nickname + '-' + item.name : item.nickname}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: params.page,
            total: total,
            pageSize: params.page_size
          }}
          onChange={e => tableChange(e)}
        />
      </div>
    </div>
  )
}

const Staff_management_detail = () => {
  let history = useHistory()
  const [id, idSet] = useState()
  const [data, dataSet] = useState({})

  useEffect(() => {
    let id = history.location.pathname.split('/')[3]
    idSet(id)
  }, [])

  useEffect(() => {
    if (id) {
      get_employee_detailFun()
    }
  }, [id])

  const get_employee_detailFun = e => {
    let data = { employee_id: id }
    get_employee_detail(data).then(res => {
      dataSet(res.data.model)
    })
  }

  return (
    <div>
      <div>
        <Divider orientation="left">员工信息</Divider>
        <Descriptions title="">
          <Descriptions.Item label="姓名">{data.name}</Descriptions.Item>
          <Descriptions.Item label="个人手机号">{data.personal_mobile}</Descriptions.Item>
          <Descriptions.Item label="工作手机号">{data.work_mobile ? data.work_mobile : '无'}</Descriptions.Item>
          <Descriptions.Item label="权限">{data.permission === '1' ? '系统管理员' : data.permission === '2' ? '管理员' : data.permission === '3' ? '校长' : data.permission === '4' ? '主管' : data.permission === '5' ? '普通员工' : '未设置'}</Descriptions.Item>
          <Descriptions.Item label="职级">{data.level === '1' ? '专员' : data.level === '2' ? '组长' : data.level === '3' ? '主管' : data.level === '6' ? '总监' : '未设置'}</Descriptions.Item>
          <Descriptions.Item label="职位">{data.position ? data.position : '未设置'}</Descriptions.Item>
          <Descriptions.Item label="职位类型">{data.postion_type === '1' ? '全职' : data.postion_type === '2' ? '兼职' : '未设置'}</Descriptions.Item>
          <Descriptions.Item label="是否是教师">{data.is_teaching === '1' ? '是' : data.is_teaching === '2' ? '否' : '未设置'}</Descriptions.Item>
        </Descriptions>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="粉丝管理" key="1">
          <myContext.Provider value={{ id: id }}>
            <My_user_list />
          </myContext.Provider>
        </TabPane>
        <TabPane tab="微信管理" key="2">
          <myContext.Provider value={{ id: id }}>
            <Employee_unionid_list />
          </myContext.Provider>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Staff_management_detail;